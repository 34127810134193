/* Please see documentation at https://docs.microsoft.com/aspnet/core/client-side/bundling-and-minification\
for details on configuring this project to bundle and minify static web assets. */

body {
    font-family: verdana, arial, helvetica, sans-serif !important;
}

header {
    position: fixed;
    z-index: 10;
    background-color: white;
    width: 100%;
    top: 0;
}

header .mb-3 {
    margin: 0 !important;
}

a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

a {
  color: #0077cc;
}

.form-control:focus {
    border-color: #0077cc;
    box-shadow: 0 0 0 0.2rem #0077cc;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.btn-link.nav-link:focus {
  outline: black auto 1px;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.container {
  max-width: 960px;
}

.pricing-header {
  max-width: 700px;
}

.border-top {
  border-top: 1px solid #e5e5e5;
}
.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

button.accept-policy {
  font-size: 1rem;
  line-height: inherit;
}

html {
  position: relative;
  min-height: 100%;
}

body {
  margin-bottom: 60px;
}

.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    white-space: nowrap;
    line-height: 60px;
    background-color: white;
}

button {
    text-decoration: underline;
    border: none;
    background-color: transparent;
    color: blue;
}

label {
    margin: 0;
}

.status-box {
    text-align: center;
    width: 17px;
    display: inline-block;
    border: 1px solid black;
    margin: 1px;
}

.link {
    font-weight: bold;
    font-size: 13px;
    color: #FF9F09;
    text-decoration: underline;
    padding: 0;
}

.legend {
    margin: 5px;
    padding: 5px;
}

.legend-box {
    border: 1px solid;
    width: max-content;
    padding: 5px;
}

.contents {
    margin-bottom: 150px;
    top: 65px;
    position: relative;
}

.value-matrix input {
    width: 100px;
    margin-left: 2px;
    text-align: right;
}

/* Chrome, Safari, Edge, Opera */
.value-matrix input::-webkit-outer-spin-button,
.value-matrix input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.value-matrix input[type=number] {
    -moz-appearance: textfield;
}